//theme
import 'primereact/resources/themes/lara-dark-blue/theme.css'
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './App.css';

import logo from './logo.png';

import {Fragment, useEffect, useState} from "react";

import {Message} from "primereact/message";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";


const API_ROUTE = "/illushield-api";

function UIFragment({rendered, children}) {
    if (!rendered)
        return (<></>);
    return children;
}

const apiCheckInitialStatus = async () => {
    try {
        const response = await fetch(`${API_ROUTE}/check-authentication`)
        return response.status === 200;
    } catch (e) {
        return false;
    }
};

const apiAuthenticate = async (username, password) => {
    const query = {
        method: "POST",
        mode: "same-origin",
        cache: "no-cache",
        referrerPolicy: "no-referrer",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({username, password}),
    };
    try {
        const response = await fetch(`${API_ROUTE}/authenticate`, query)
        try {
            const json = await response.json();
            return {
                successful: response.status === 200,
                message: json.status
            };
        } catch (e) {
            console.log("Error parsing JSON", e);
            return {
                successful: false,
                message: "Invalid Response"
            };
        }
    } catch (e) {
        return {
            successful: false,
            message: "Error communicating with server"
        };
    }
};

const apiUnauthenticate = async (username) => {
    const query = {
        method: "POST",
        mode: "same-origin",
        cache: "no-cache",
        referrerPolicy: "no-referrer",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({username}),
    };
    await fetch(`${API_ROUTE}/unauthenticate`, query);
};

const STATE_INITIALIZING = "initializing";
const STATE_IDLE = "idle";
const STATE_LOADING = "loading";
const STATE_AUTHENTICATED = "authenticated";
const STATE_REDIRECT = "redirect";

function App() {

    const [state, setState] = useState(STATE_INITIALIZING);
    const [errorMessage, setErrorMessage] = useState("");
    const [username, setUsername] = useState(null);
    const [password, setPassword] = useState(null);

    /** @type {string} */
    const usernameClean = username || "";
    /** @type {string} */
    const passwordClean = password || "";


    const hasError = (state === STATE_IDLE && !!errorMessage);
    const hasUsernameError = (state === STATE_IDLE && (username === "" || hasError));
    const hasPasswordError = (state === STATE_IDLE && (password === "" || hasError));
    const formVisible = (state === STATE_IDLE || state === STATE_LOADING);
    const formEnabled = (state === STATE_IDLE);
    const sendPossible = (!!username && !!password) && formEnabled;
    const isLoading = (state === STATE_LOADING);

    useEffect(() => {
        apiCheckInitialStatus().then((successful) => setState(successful ? STATE_AUTHENTICATED : STATE_IDLE));
    }, []);

    const changeUsername = (e) => setUsername(e.target.value);
    const changePassword = (e) => setPassword(e.target.value);

    const signIn = () => {
        if (!sendPossible)
            return;

        setState(STATE_LOADING)
        setErrorMessage("");
        setPassword(null);
        apiAuthenticate(username, password).then((result) => {
            const {successful, message} = result;
            if (successful) {
                setState(STATE_AUTHENTICATED);
                redirectToReturnUrl();
            } else {
                setState(STATE_IDLE);
                setErrorMessage(message);
                window.setTimeout(() => {
                    const pwInput = document.getElementById("password");
                    if (pwInput) {
                        pwInput.focus();
                    }
                });
            }
        });
    };

    const signOut = () => {
        setState(STATE_INITIALIZING);
        apiUnauthenticate(username).then(() => {
            setState(STATE_IDLE);
            setUsername(null);
            setPassword(null);
            setErrorMessage("");
        }).catch((e) => {
            console.log("Unauthentication not successful", e);
            setState(STATE_AUTHENTICATED);
        });
    };

    const redirectToReturnUrl = () => {
        const search = window.location.search;
        if (!search)
            return;
        const searchParams = new URLSearchParams(search);
        if (!searchParams.has("return_url"))
            return;
        const returnUrl = searchParams.get("return_url");
        setState(STATE_REDIRECT);
        window.setTimeout(() => {
            window.location.href = returnUrl;
        }, 1000);
    };

    return (
        <div className="App">

            <div className="LoginContainer flex align-items-center justify-content-center">
                <div className="LoginBox surface-card p-4 shadow-2 border-round w-full lg:w-6">
                    <div className="text-center mb-5">
                        <img src={logo} alt="hyper" height={75} className="mb-3"/>
                        <div className="text-600 font-medium line-height-3">Please sign in to illucIT Intranet</div>
                    </div>
                    <UIFragment rendered={state === STATE_INITIALIZING}>
                        <Message severity="info" className="w-full"
                                 text="Checking authentication status ..."
                        />
                    </UIFragment>
                    <UIFragment rendered={state === STATE_AUTHENTICATED}>
                        <Message severity="success" className="w-full"
                                 text="You are authenticated and can access the website."
                        />

                        <Button label="Sign Out" icon="pi pi-sign-out" className="w-full mt-3"
                                onClick={signOut}
                        />
                    </UIFragment>
                    <UIFragment rendered={state === STATE_REDIRECT}>
                        <Message severity="success" className="w-full"
                                 text="You are authenticated and can access the website."
                        />

                        <Button label="Redirecting back to application ..." icon="pi pi-spin pi-spinner" className="w-full mt-3"
                                disabled
                        />
                    </UIFragment>
                    <UIFragment rendered={formVisible}>

                        <form onSubmit={(e) => {
                            signIn();
                            e.preventDefault();
                            return false;
                        }}>
                            <UIFragment rendered={hasError}>
                                <Message severity="error" className="w-full mb-6"
                                         text={errorMessage}
                                />
                            </UIFragment>

                            <div className="field grid">
                                <label htmlFor="username" className="col-12 mb-3 md:col-3 md:mb-0">Username</label>
                                <div className="col-12 md:col-9">
                                    <InputText id="username" value={usernameClean} disabled={!formEnabled}
                                               onChange={changeUsername}
                                               className={hasUsernameError ? 'w-full p-invalid' : 'w-full'}
                                               autoFocus
                                    />
                                </div>
                            </div>

                            <div className="field grid">
                                <label htmlFor="password" className="col-12 mb-3 md:col-3 md:mb-0">Password</label>
                                <div className="col-12 md:col-9">
                                    <InputText id="password" value={passwordClean} type="password" disabled={!formEnabled}
                                               onChange={changePassword}
                                               className={hasPasswordError ? 'w-full p-invalid' : 'w-full'}
                                    />
                                </div>
                            </div>

                            <Button label={isLoading ? 'Signing in ...' : 'Sign In'}
                                    icon={isLoading ? 'pi pi-spin pi-spinner' : 'pi pi-sign-in'}
                                    className="w-full mt-3" disabled={!sendPossible} onClick={signIn}
                            />
                        </form>
                    </UIFragment>
                </div>
            </div>
        </div>
    );
}

export default App;
